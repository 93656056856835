
import { Options, Vue } from 'vue-class-component';
import { BagEstimationClient } from '@/services/Services';
import AdditionalProperties from './components/additionalProperties.vue';
import * as OM from '@/Model';

@Options({
    components: {
        AdditionalProperties
    }
})
export default class Chemicals extends Vue {

    estimationIdentifier: string = "";
    chemicalsFromConfiguration: OM.BagAdditionalPropertiesConfiguration[] = [];
    estimationChemicals: OM.BagAdditionalProperty[] = [];

    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            BagEstimationClient.getChemicalsFromConfiguration(),
            BagEstimationClient.getChemicalsAdditionalsFromEstimation(this.estimationIdentifier)
        ])
        .then( xs => {
            this.chemicalsFromConfiguration = xs[0];
            this.estimationChemicals = xs[1];

            setTimeout(() => {
                this.loaded = true;
            }, 300);
        })
    }

    updateAdditionalProperties(model: OM.BagEstimationAdditionalPropertyVM) {
        BagEstimationClient.updateAdditionalProperties(model)
        .then(x => {
            this.$router.push('/bag-calculator/accessories/' + this.estimationIdentifier);
        })
    }
}
