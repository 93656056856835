
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class AdditionalProperties extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() type: string;

    @Prop() propertiesFromConfiguration: OM.BagAdditionalPropertiesConfiguration[] = [];
    @Prop() estimationProperties: OM.BagAdditionalProperty[] = [];

    yesOrNoSelect: OM.TextIdentifier[] = [];
    selectedValues: { [key: string]: string; } = {};
    selectedValuesAmount: { [key: string]: number; } = {};
    
    created() {
        this.yesOrNoSelect = [
            {
                identifier: true.toString(),
                text: this.$localizationService.getLocalizedValue("Yes", "Yes")
            },
            {
                identifier: false.toString(),
                text: this.$localizationService.getLocalizedValue("No", "No")
            }
        ];

        this.estimationProperties.forEach(element => {
            if(element.category) {
                this.selectedValues[element.category] = "true";
                this.selectedValues[element.category + "_" + element.name] = "true";

                if(element.isUnitCalculated && element.amount > 0)
                    this.selectedValuesAmount[element.category + "_" + element.name] = element.amount;
            }
            else {
                this.selectedValues[element.name] = "true";

                if(element.isUnitCalculated && element.amount > 0)
                    this.selectedValuesAmount[element.name] = element.amount;
            }
        });
    }

    groupByVisualCategory = (items) => {
        return items.reduce((acc, item) => {
            const key = item.visualCategory;
            if (!acc[key]) {
            acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
    };
    groupByCategory = (items) => {
        return items.reduce((acc, item) => {
            const key = item.category;
            if (!acc[key]) {
            acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
    };
    get groupByVisualCategoryAndCategory() {
        const visualCategoryGroups = this.groupByVisualCategory(this.propertiesFromConfiguration);
        for (const visualCategory in visualCategoryGroups) {
            visualCategoryGroups[visualCategory] = this.groupByCategory(visualCategoryGroups[visualCategory]);
        }
        return visualCategoryGroups;
    };

    get checkIfDisabled() {
        var keys = Object.keys(this.selectedValues);
        var undefinedItems = keys.filter(x => x.includes("undefined"));
        
        undefinedItems.forEach(element => {
            var dividerPosition = element.indexOf("_");
            var parent = keys.filter(x => x == element.substring(0, dividerPosition))[0];
            if(parent && this.selectedValues[parent] == "false") {
                var indexToRemove = keys.indexOf(element);
                keys.splice(indexToRemove, 1);
            }
        });

        return keys.filter(x => x.includes("undefined")).length > 0;
    }

    checkValue(name: string) {
        return this.selectedValues[name] ? this.selectedValues[name].toString() : false.toString();
    }
    checkMiddleValue(key: string) {
        if(this.checkValue(key) == 'false')
            return false;
            
        var keys = Object.keys(this.selectedValues);
        return keys.indexOf(key + "_undefined") == -1;
    }
    checkIfSubValue(key: string) {
        var exist = this.estimationProperties.filter(x => x.category == key)[0];
        return exist ? exist.name : 'undefined';
    }
    getMixedName(key: string, name: string) {
        return key + "_" + name;
    }
    getSelectedKey(key: string) {
        var keys = Object.keys(this.selectedValues);
        return keys.filter(x => x.includes(key + "_"))[0];
    }

    updateDictionaryKey(value: string, name: string){
        this.selectedValues[name] = value;
    }
    updateDetail(value: string, key: string) {
        var finalKey = key + "_" + value;

        var keys = Object.keys(this.selectedValues);
        keys.forEach(item => {
            if(item.includes(key + "_"))
                delete this.selectedValues[item];
        });
        this.selectedValues[finalKey] = true.toString();

        var existingItemFromConfig = this.propertiesFromConfiguration.filter(x => x.name == key || x.category == key)[0];
        if(existingItemFromConfig && existingItemFromConfig.isUnitCalculated) {
            var amountKeys = Object.keys(this.selectedValuesAmount);
            amountKeys.forEach(amountItem => {
                if(amountItem.includes(key + "_"))
                    delete this.selectedValuesAmount[amountItem];
            });
            this.selectedValuesAmount[finalKey] = 1;
        }
    }

    @Watch('selectedValues', { deep: true })
    initializeAmountValues() {
        var keys = Object.keys(this.selectedValues);

        keys.forEach(key => {
            if(this.selectedValues[key]) {
                var item = this.propertiesFromConfiguration.filter(x => x.name == key)[0];

                if(!item)
                    return;

                var keyForDict = item.category ? item.category + "_" + item.name : item.name;

                if(item && item.isUnitCalculated && this.selectedValues[keyForDict] == 'true') {
                    if(!(this.selectedValuesAmount[keyForDict] > 1))
                        this.selectedValuesAmount[keyForDict] = 1;
                }
            }
        });
    }

    updateAdditionalProperties() {
        var model = new OM.BagEstimationAdditionalPropertyVM();
        model.identifier = this.estimationIdentifier;
        model.type = this.type;
        
        var keys = Object.keys(this.selectedValues);
        keys.forEach(key => {
            if(this.selectedValues[key] == "false")
                return;
                
            if(key.includes("_")) {
                var category = key.substring(0, key.indexOf("_"));

                if(this.selectedValues[category] == "false")
                    return;

                key = key.substring(key.indexOf("_") + 1);
            }

            var config = this.propertiesFromConfiguration.filter(x => x.name == key)[0];
            if(config) {
                var propertyWithAmount = new OM.BagAdditionalProperty();
                propertyWithAmount.createdOn = config.createdOn;
                propertyWithAmount.modifiedOn = config.modifiedOn;
                propertyWithAmount.identifier = config.identifier;
                propertyWithAmount.category = config.category;
                propertyWithAmount.name = config.name;
                propertyWithAmount.type = config.type;
                propertyWithAmount.emissionFactor = config.emissionFactor;
                propertyWithAmount.isUnitCalculated = config.isUnitCalculated;

                var key = config.category ? config.category + "_" + config.name : config.name;
                propertyWithAmount.amount = this.selectedValuesAmount[key];

                model.additionalProperties.push(propertyWithAmount);
            }
        });

        this.$emit('modelUpdated', model);
    }
    
}
